'use client';
import { AhafoodLogo } from '@ahamove/icons/order';
import { cn } from '@ahm/common-helpers';
import { usePathname } from 'next/navigation';
import classes from './main-layout.module.css';
import LoginButton from '@/components/fb-login-btn';
import { Link } from '@/i18n/routing';

export default function MainHeader() {
  const pathname = usePathname();
  return (
    <header
      className={cn(
        pathname === '/store' ? classes.headerStore : classes.header
      )}
    >
      <div className="container mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-4 p-4 sm:flex-row md:h-24 md:gap-6 md:px-6">
        <div className="flex w-full flex-1 flex-row flex-nowrap items-center">
          <Link className="flex flex-none items-center justify-start" href="/">
            <AhafoodLogo className="h-10 w-36 sm:h-[64px] sm:w-[259px]" />
            <h1 className="sr-only">Ahamove</h1>
          </Link>
        </div>
        <div className="m-0 flex w-full flex-nowrap justify-end sm:w-3/5">
          <LoginButton />
        </div>
      </div>
    </header>
  );
}
