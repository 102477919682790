'use client';

import { FacebookWhite } from '@ahamove/icons';
import { cn } from '@ahm/common-helpers';
import { ChevronDown, Loader2, LogOut } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import HeaderFilter from './store/header-filter';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { usePathname } from '@/i18n/routing';
import { useAuth } from '@/providers/auth-provider';

export default function AuthButton() {
  const pathname = usePathname();
  const { status, user, login, logout } = useAuth();
  const router = useRouter();
  const handleLogout = () => {
    logout();
    router.push('/');
  };

  const isLoading = status === 'loading';
  if (pathname === '/store') return <HeaderFilter />;

  return (
    <div className="w-full md:max-w-fit">
      {status === 'authorized' ? (
        <div className="flex items-center justify-center space-x-6">
          <Link passHref href="/pages">
            <Button
              variant="link"
              className={cn(
                'h-[50px] w-full rounded bg-transparent text-xl font-bold !no-underline hover:bg-transparent md:min-w-[232.66px] md:px-12',
                {
                  'text-neutral-50 hover:text-neutral-70':
                    pathname !== '/pages',
                  'text-primary-60': pathname === '/pages',
                }
              )}
              disabled={isLoading}
            >
              Trang của bạn
            </Button>
          </Link>
          <DropdownMenu>
            <DropdownMenuTrigger
              asChild
              className="text-neutral-50 hover:text-neutral-70 focus:text-neutral-70 active:text-neutral-70"
            >
              <div className="flex py-4">
                <span className="w-full max-w-96 self-center truncate bg-transparent text-xl font-bold">
                  Hi, {user?.name}
                </span>
                <ChevronDown className="ml-2 size-6 flex-none" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleLogout} className="font-medium">
                <LogOut className="mr-2 size-4" />
                Đăng xuất
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : (
        <Button
          variant="default"
          className="h-[60px] w-full min-w-52 rounded px-12 text-xl font-bold"
          onClick={login}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 size={24} className="text-neutral-500 animate-spin" />
          ) : (
            <>
              <FacebookWhite className="mr-2 size-6" /> Đăng nhập
            </>
          )}
        </Button>
      )}
    </div>
  );
}
