import type { Address } from '@ahm/api-wrappers-core';
import { cn } from '@ahm/common-helpers';
import { X } from 'lucide-react';
import Autocomplete from './items/address-autocomplete';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { CITIES, useAiFoodOrderStore } from '@/store';

export type StoreCityIdDialogProps = {
  showCityPopup: boolean;
  setShowCityPopup: (show: boolean) => void;
  address: Address | null;
  setAddress: (address: Address | null) => void;
};

export default function StoreCityIdDialog({
  showCityPopup,
  setShowCityPopup,
  address,
  setAddress,
}: StoreCityIdDialogProps) {
  const cityId = useAiFoodOrderStore.use.city_id();
  const setCityId = useAiFoodOrderStore.use.setCityId();

  const handleSetAddress = (newAddress: Address) => {
    setAddress(newAddress);
    setShowCityPopup(false);
  };

  return (
    <Dialog open={showCityPopup} onOpenChange={setShowCityPopup} modal>
      <DialogContent
        className="flex size-full min-h-svh flex-col gap-0 p-0 md:h-auto md:min-h-[592px] md:max-w-[448px] [&>button>svg]:size-6 [&>button]:right-3 [&>button]:top-3"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <DialogHeader className="z-10 flex-none rounded-md bg-white py-1.5 pl-4 pr-2">
          <div className="flex items-center justify-between">
            <DialogTitle className="text-xl font-bold text-neutral-80">
              Chọn vị trí nhận món
            </DialogTitle>
            <DialogClose asChild>
              <Button
                variant="ghost"
                size="icon"
                className={cn('rounded-full', {
                  hidden: !cityId,
                })}
              >
                <X className="size-6" />
              </Button>
            </DialogClose>
          </div>
        </DialogHeader>
        <ScrollArea className="flex-1 overflow-y-auto">
          <Tabs
            defaultValue={cityId ? 'address' : 'city'}
            className="w-full p-4"
          >
            <TabsList className="grid h-12 w-full grid-cols-2">
              <TabsTrigger
                disabled={!cityId}
                className="h-full text-sm font-bold"
                value="address"
              >
                Địa chỉ nhận món
              </TabsTrigger>
              <TabsTrigger className="h-full text-sm font-bold" value="city">
                Thành phố
              </TabsTrigger>
            </TabsList>
            <TabsContent className="mt-4 min-h-96" value="address">
              <Autocomplete address={address} onChange={handleSetAddress} />
            </TabsContent>
            <TabsContent className="mt-4 min-h-96" value="city">
              <h1 className="text-lg font-bold">
                Chọn thành phố bạn muốn đặt món
              </h1>

              <div className="mt-4 w-full space-y-4">
                {CITIES.map((city) => (
                  <Button
                    variant="outline"
                    className={cn(
                      'h-12 w-full rounded-xl px-4 py-2 text-base font-bold md:h-16',
                      {
                        'border-[2px] border-primary-50 text-primary-50':
                          city.value === cityId,
                      }
                    )}
                    key={city.value}
                    value={city.value}
                    onClick={() => setCityId(city.value)}
                  >
                    {city.label}
                  </Button>
                ))}
              </div>
            </TabsContent>
          </Tabs>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
