'use client';

import { ChevronDown, Search, X } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { DebouncedInput } from '../debounced-input';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import StoreCityIdDialog from '@/app/[locale]/(main)/store/components/store-city-id-dialog';
import { useDebouncedValue } from '@/hooks/use-debounced-value';
import { usePathname, useRouter } from '@/i18n/routing';
import { CITIES, useAiFoodOrderStore } from '@/store';

export default function HeaderFilter() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState<boolean>(false);
  const [itemSearch, setItemSearch] = useState<string>(
    searchParams.get('q') ?? ''
  );

  const cityId = useAiFoodOrderStore.use.city_id();
  const address = useAiFoodOrderStore.use.address();
  const setAddress = useAiFoodOrderStore.use.setAddress();
  const debouncedItemSearch = useDebouncedValue(itemSearch, 500);

  const selectedCity = useMemo(() => {
    return CITIES.find((c) => c.value === cityId);
  }, [cityId]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (address) {
      params.delete('category');
    }
    if (debouncedItemSearch) {
      params.delete('category');
      params.set('q', debouncedItemSearch);
    } else {
      params.delete('q');
    }
    const newUrl = `${pathname}?${params.toString()}`;
    router.push(newUrl, { scroll: false });
  }, [address, debouncedItemSearch, pathname, router, searchParams]);

  useEffect(() => {
    setIsOpenSearchDialog(!cityId ? true : false);
  }, [cityId]);

  useEffect(() => {
    setItemSearch(searchParams.get('q') ?? '');
  }, [searchParams]);

  return (
    <>
      <StoreCityIdDialog
        showCityPopup={isOpenSearchDialog}
        setShowCityPopup={setIsOpenSearchDialog}
        setAddress={setAddress}
        address={address}
      />
      <div className="flex w-full flex-col justify-end gap-3 md:flex-row md:gap-4">
        <div className="relative w-full md:max-w-80">
          {address ? (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-2 top-[14px] size-5 p-0 md:top-5"
              aria-label="Clear input"
              onClick={() => {
                setAddress(null);
              }}
            >
              <X className="" />
            </Button>
          ) : null}
          <label className="absolute left-[18px] top-2 flex flex-none items-center text-xs font-medium text-neutral-70 md:top-3">
            Vị trí nhận món
            <ChevronDown className="ml-1" color="orange" size={16} />
          </label>
          <Input
            className="h-12 w-full justify-start truncate rounded-xl border-neutral-20 bg-neutral-10/30 pl-4 pr-10 text-sm font-bold hover:bg-neutral-30/30 md:h-16 md:max-w-96"
            defaultValue={
              address?.address ?? selectedCity?.label ?? 'Nhập vị trí của bạn'
            }
            aria-haspopup="listbox"
            aria-expanded={isOpenSearchDialog}
            style={{
              paddingTop: 25,
            }}
            onClick={() => setIsOpenSearchDialog(true)}
          />
        </div>

        <div className="relative flex w-full flex-row flex-nowrap items-center md:max-w-80">
          <Search className="absolute left-4 top-1/2 size-6 flex-none -translate-y-1/2" />
          <DebouncedInput
            value={itemSearch}
            onChange={(value) => {
              setItemSearch(String(value));
            }}
            className="inline-flex h-12 w-full items-center justify-start truncate whitespace-nowrap rounded-xl border border-neutral-20 bg-neutral-10/30 px-4 py-2 pl-12 text-sm font-bold ring-offset-background transition-colors hover:bg-neutral-30/30 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 md:h-16 md:max-w-96 md:text-base"
            placeholder="Bạn muốn ăn gì?"
            type="search"
            id="search"
          />
        </div>
      </div>
    </>
  );
}
